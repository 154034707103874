"use client";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { Linkedin } from "./svg";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

export default function Header() {
  // use index from api to change the dress & body structure

  const [selected, setSelected] = useState(0);
  const data = [
    {
      name: "Pratik Padamwar",
      role: "Co-Founder & CEO",
      link: "https://www.linkedin.com/in/pratik-padamwar-810b9666/",
      imageFull: "/assets/images/Pratik_Padamwar_full.png",
      imageHalf: "/assets/images/Pratik_Padamwar_half.png",
    },
    {
      name: "Prabhat Mishra",
      role: "Co-Founder & CTO",
      link: "https://www.linkedin.com/in/prabhat-mishra-870256b7/",
      imageFull: "/assets/images/Prabhat_Mishra_full.png",
      imageHalf: "/assets/images/Prabhat_Mishra_half.png"
    },
    {
      name: "Ravindrababu Ravula",
      role: "CMO",
      link: "https://www.linkedin.com/in/ravindrababu-ravula/",
      imageFull: "/assets/images/Ravindra_Babu_full.png",
      imageHalf: "/assets/images/Ravindra_Babu_half.png"
    },
    {
      name: "Dipendra Kumar Shah",
      role: "Softwere Engineer",
      link: "https://www.linkedin.com/in/dikushrd/",
      imageFull: "/assets/images/Dipendra_Kumar_Shah_full.png",
      imageHalf: "/assets/images/Dipendra_Kumar_Shah_half.png"
    },
    {
      name: "Paarth Madan",
      role: "Deep Learning Research Engineer",
      imageFull: "/assets/images/Paarth_Madan_full.png",
      imageHalf: "/assets/images/Paarth_Madan_half.png"
    },
    // {
    //   name: "Sarthak Jain",
    //   role: "Machine Learning Engineer",
    //   link: "https://www.linkedin.com/in/sartj",
    //   imageFull: "/assets/images/Sarthak_Jain_full.png",
    //   imageHalf: "/assets/images/Sarthak_Jain_half.png"
    // },
    // {
    //   name: "Bharath Kumar",
    //   role: "3d Character Artist",
    //   imageFull: "/assets/images/Bharath_Kumar_full.png",
    //   imageHalf: "/assets/images/Bharath_Kumar_half.png"
    // },
    {
      name: "Anil Kumar",
      role: "3d Artist and Animator",
      link: "https://www.linkedin.com/in/anilkumar-kolluru-744431317/",
      imageFull: "/assets/images/Anil_Kumar_full.png",
      imageHalf: "/assets/images/Anil_Kumar_half.png"
    },
    {
      name: "Khushi Agrawal",
      role: "UI/UX Designer",
      link: "https://www.linkedin.com/in/khushi-agrawal-3183a2222/",
      imageFull: "/assets/images/Khushi_Agrawal_full.png",
      imageHalf: "/assets/images/Khushi_Agrawal_half.png"
    },
    {
      name: "Vibhor Sharma",
      role: "Marketing Lead",
      link: "https://www.linkedin.com/in/vibhor-sharma-a9649b246/",
      imageFull: "/assets/images/Vibhor_Sharma_full.png",
      imageHalf: "/assets/images/Vibhor_Sharma_half.png"
    },
    {
      name: "Anand Rajawat",
      role: "Marketing Associate",
      link: "https://www.linkedin.com/in/anandrajawat/",
      imageFull: "/assets/images/Anand_Rajawat_full.png",
      imageHalf: "/assets/images/Anand_Rajawat_half.png"
    },
    {
      name: "Ankit Joshi",
      role: "Business Development and Finance Head",
      link: "https://www.linkedin.com/in/ca-ankit-joshi-225171122/",
      imageFull: "/assets/images/Ankit_Joshi_full.png",
      imageHalf: "/assets/images/Ankit_Joshi_half.png"
    }

  ];
  return (
    <div
      className="relative overflow-hidden flex md:flex-row flex-col-reverse items-center justify-center gap-8 p-8"
      style={{
        minHeight: "calc(100vh - 80px)",
        backgroundImage: "url(/assets/images/about-back.png)",
        backgroundSize: "contain",
        backgroundSize: "100% 100%",
      }}
    >
      <motion.img
        src="/assets/images/side2.svg"
        className=" md:block hidden absolute top-3 right-0 translate-x-[15%] xl:h-[30%] lg:h-[20%] h-[15%]"
      />
      <div
        className="aspect-square h-[80%] absolute bg-pur -z-50"
        style={{
          filter: "blur(800px)",
        }}
      />
      <div className="min-w-[35%] grid place-content-center relative">
        {/* api Based data */}
        {/* <div className=" border absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] bg-white p-2 text-black *:my-2">
          image {selected}
        </div> */}
        {/* api Based data */}
        <motion.img
          src={data[selected].imageFull || "/assets/images/member.svg"}
          className=" w-full max-w-[120px] md:max-w-[270px] "
        />
      </div>

      <div className="max-w-[1440px] w-full flex md:flex-col flex-col-reverse gap-2">
        <div className="border-b flex items-center justify-between gap-8 max-w-[640px] w-full md:mx-0 mx-auto p-4">
          <div className=" flex flex-col gap-2">
            <h1 className=" font-bold lg:text-4xl"> {data[selected].name} </h1>
            <h1 className="font-normal lg:text-lg"> {data[selected].role} </h1>
          </div>
          {data[selected].link &&
            <a target="_blank" href={data[selected].link} className="border rounded-md p-1">
              <Linkedin size={36} fill={"#fff"} />
            </a>}
        </div>

        <div
          className="">
          <div
            className="px-4 t-head font-bold md:text-start text-center mt-12 mb-11 la tracking-wider"
            style={{ lineHeight: 0 }}
          >
            {" "}
            <span className="text-lb">Team</span> Members{" "}
          </div>
          <div className=" hidden md:grid place-content-center grid-cols-5 gap-2 px-2 max-w-[800px]">
            {data.map((info, i) => {
              return (
                <div
                  key={i}
                  className="h-auto rounded-xl border border-[#00FFFF] cursor-pointer bg-slate-800 grid place-content-center hover:bg-cyan-500 duration-200"
                  onClick={() => setSelected(i)}
                  style={{
                    boxShadow: "inset lightgray 0px 0px 30px -12px",
                  }}
                >
                  <motion.img
                    src={info.imageHalf || "/assets/images/team.svg"}
                    className="h-[210px]"
                  />
                </div>
              );
            })}
          </div>

          <div className="md:hidden h-[250px]">
            <Swiper
              spaceBetween={30}
              loop={true}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                },
                480: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 3,
                },
              }}
              className="h-full"
            >
              {data.map((info, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div
                      onClick={() => setSelected(i)}
                      className="bg-[#ffffff20] h-full grid place-content-center rounded-xl hover:bg-[#82ddf170] duration-200"
                    >
                      <motion.img
                        src={info.imageHalf || "/assets/images/team.svg"}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
